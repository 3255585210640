import { ConvertedFunctionParamProps } from "../../types/ConvertedFunctionParamProps";
import { GetUserProps } from "../../types/GetUserProps";

export const get_list_courses = async (
    args: ConvertedFunctionParamProps<GetUserProps>
): Promise<string> => {
    const { user_id } = args;
    try {
      let allCourses = [];
      let page = 1;
      const per_page = 100;
      const options = {
        method: 'GET',
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_LEARNING_API_TOKEN}`
        }
      };
      while (true) {
        const response = await fetch(`${process.env.REACT_APP_LEARNING_URL}/wp-json/ldlms/v1/sfwd-courses?per_page=${per_page}&page=${page}`, options);
        if (!response.ok) {
          throw new Error('Unable to Fetch Data, Please check URL or Network connectivity!');
        }

        const totalPages = parseInt(response.headers.get('x-wp-totalpages') || '0', 10);
        
        const data = await response.json();
        allCourses = allCourses.concat(data);
        
        if (page >= totalPages) {
          // We have fetched all the pages
          break;
        }
        
        page++;
      }

      // too long json stringify
      // const formattedCourses = {
      //   user_id: user_id,
      //   courses: allCourses.map(course => ({
      //     id: course.id,
      //     date: course.date,
      //     date_gmt: course.date,
      //     modified: course.modified,
      //     modified_gmt: course.modified_gmt,
      //     slug: course.slug,
      //     status: course.status,
      //     type: course.type,
      //     link: course.link,
      //     title: course.title.rendered,
      //     content: course.content.rendered,
      //     ld_course_category: course.ld_course_category,
      //     ld_course_tag: course.ld_course_tag
      //   }))
      // };
      // return JSON.stringify(formattedCourses);

      // return allCourses.map(course => `${course.title.rendered} (Status: ${course.status})`).join(',');
      return JSON.stringify({ courses: allCourses.map(course => `${course.title.rendered} (Status: ${course.status})`).join(',') });
    } catch (error) {
      console.error(error);
      return JSON.stringify({ message: 'Failed to fetch user courses', error: error });
    }
};
