import { ConvertedFunctionParamProps } from "../../types/ConvertedFunctionParamProps";
import { NoArgsProps } from "../../types/NoArgsProps";

export const get_today = async (
    args: ConvertedFunctionParamProps<NoArgsProps>
): Promise<string> => {
    // const { } = args;
    try {
        return JSON.stringify({
            today: new Date().toLocaleString()
        });
    } catch (error) {
        console.error(error);
        return JSON.stringify({ message: 'Failed to fetch current datetime', error: error });
    }
};