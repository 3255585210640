// src/components/UserIcon.tsx
import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";
// import themeOptions from "../theme/styleTheme";
import { loadAndOverrideEnvVars } from '../utils/common-utils';
import styleHelloTheme from "../theme/hello/styleTheme";
import styleWASTheme from "../theme/was/styleTheme";

const configWPShortCode = loadAndOverrideEnvVars();

const UserIcon: React.FC<SvgIconProps> = (props) => {
  const themeOptions = configWPShortCode.theme === 'hello' ? styleHelloTheme : styleWASTheme;
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
          <rect strokeWidth={0} fill={`${themeOptions.palette.primary.main}`} width="24" height="24"/>
          <path strokeWidth={0} fill="#ffffff" d="M3.71,22.95l-.88-.67c1.07-.98,1.43-1.65,1.63-2.04-.18.06-.37.13-.72.13-1.19,0-2.17-.9-2.17-2.3,0-1.48,1.07-2.35,2.31-2.35.9,0,2.31.52,2.31,2.48,0,1.81-.8,3.21-2.48,4.74Z"/>
      </svg>
    </SvgIcon>
  );
};

export default UserIcon;
