// src/components/ChatHeader.tsx
import React from "react";
import { AppBar, Grid, Typography, Toolbar } from "@mui/material";
// import themeOptions from "../theme/styleTheme";
// import BotIcon from "./BotIcon";

const ChatHeader: React.FC<{text: string}> = ({text}) => {
  return (
    <AppBar position="static" sx={{ borderRadius: "16px 16px 0 0", zIndex: 1 }}>
      <Toolbar>
        <Grid
          container
          wrap="nowrap"
          display="flex"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{
            '.MuiGrid-item': {
              paddingLeft: 0
            }
          }}
        >
          {/* <Grid item>
            <BotIcon />
          </Grid> */}
          <Grid item xs textAlign="center" textTransform="uppercase">
            <Typography variant="h5" sx={{ color: '#fff !important', marginBottom: '0 !important', fontWeight: '600 !important', fontSize: '1.5rem !important' }}>{text}</Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default ChatHeader;
