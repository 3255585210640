// src/components/Header.tsx
import { AppBar, Box, Toolbar } from '@mui/material';
// import HelloLogo from '../images/hello-black.png';
import BotIcon from "./BotIcon";

export const Header = () => (
  <AppBar
    // className="App-header"
    position="static"
    sx={{
      background: '#ffffff',
      // height: '7vh',
      // marginBottom: '.5rem',
      boxShadow: 'none'
    }}>
    <Toolbar>
      <Box
        display="flex"
        flexGrow={1}
        justifyContent="center"
        // onClick={() => window.open('https://thisishello.com/it', '_blank')}
        >
          <BotIcon sx={{fontSize: '5rem'}} />
        {/* <IconButton style={{ backgroundColor: 'transparent' }}>
          <img src={HelloLogo} alt="logo hello" />
        </IconButton> */}
      </Box>
    </Toolbar>
  </AppBar>
);
