// src/components/Questions.tsx
import React from "react";
// import themeOptions from "../theme/styleTheme";
import { Box, Chip, Stack, Typography } from "@mui/material";
import { loadAndOverrideEnvVars } from '../utils/common-utils';
import styleHelloTheme from "../theme/hello/styleTheme";
import styleWASTheme from "../theme/was/styleTheme";

const configWPShortCode = loadAndOverrideEnvVars();

interface ChipListProps {
  chips?: string[];
  onChipSelect: (input: string) => void;
  appendQuestions?: boolean;
}

const Questions: React.FC<ChipListProps> = ({ chips = [], onChipSelect, appendQuestions = false }) => {

  const defaultQuestions = [
    "How do I use HeyMate?",
    `What’s the Hybrid Working policy at ${configWPShortCode.theme === 'hello' ? 'Hello,' : 'We Are Social'}?`,
    "How do I request days off?",
    "How does the expense account work?"
  ];
  const questions = appendQuestions ? [...defaultQuestions, ...chips] : chips.length > 0 ? chips : defaultQuestions;

  const themeOptions = configWPShortCode.theme === 'hello' ? styleHelloTheme : styleWASTheme;

  return (
    <Box display="flex" flexDirection="column" width={'100%'}>
      <Typography variant="h5" gutterBottom textAlign="center" mt={0} mb={4} textTransform="uppercase" sx={{ color: `${themeOptions.palette.primary.main} !important`, fontWeight: '600 !important', fontSize: '1.5rem !important' }}>
        Some prompt ideas for you:
      </Typography>
      <Stack
        direction="column"
        spacing={2}
        sx={{
          maxWidth: '100%',
          overflowX: 'auto',
        }}
      >
        {questions.map((question, index) => (
          <Chip
            color="primary"
            variant="outlined"
            key={index}
            label={question}
            onClick={() => onChipSelect(question)}
            sx={{
              p: 2,
              borderRadius: 30,
              height: 'auto',
              '& .MuiChip-label': {
                display: 'block',
                fontWeight: 'bold',
                fontSize: '1rem',
                whiteSpace: 'normal !important',
              },
              "&&:hover": {
                color: "#fff",
                backgroundColor: "primary.main"
              },
              "&&:focus": {
                color: "#fff",
                backgroundColor: "primary.main"
              }
            }}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default Questions;
