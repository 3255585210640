// import {fromTimestamp} from '../utils/common-utils';
export class MessageDto {
  isUser: boolean;
  content: string;
  role: string | null;
  completed_at: number | null;
  created_at: number | null;
  incomplete_at: number | null;
  incomplete_details: any | null;

  constructor(isUser: boolean, content: string, role?: string | null, created_at?: number | null, completed_at?: number | null, incomplete_at?: number | null, incomplete_details?: any | null) {
    this.isUser = isUser;
    this.content = content;
    this.role = role;
    this.completed_at = completed_at;
    this.created_at = created_at;
    this.incomplete_at = incomplete_at;
    this.incomplete_details = incomplete_details
  }
}
