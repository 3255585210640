// src/App.tsx
import React from 'react';
import Chat from './components/Chat';
import { Container, Paper, Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
// import styleTheme from './theme/styleTheme';
import styleHelloTheme from './theme/hello/styleTheme';
import styleWASTheme from './theme/was/styleTheme';
// import { Header } from './components/Header';
import { loadAndOverrideEnvVars } from './utils/common-utils';

const configWPShortCode = loadAndOverrideEnvVars();

const App: React.FC = () => {
    return (
        <ThemeProvider theme={configWPShortCode.theme === 'hello' ? styleHelloTheme : styleWASTheme}>
            <Container>
                {/* <Header /> */}
                <Paper elevation={3}>
                    <Box display="flex" flexDirection="column" height="80vh">
                        <Chat />
                    </Box>
                </Paper>
            </Container>
        </ThemeProvider>
    );
};

export default App;
