// src/components/BotIcon.tsx
import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";
// import themeOptions from "../theme/styleTheme";
import { loadAndOverrideEnvVars } from '../utils/common-utils';
import styleHelloTheme from "../theme/hello/styleTheme";
import styleWASTheme from "../theme/was/styleTheme";

const configWPShortCode = loadAndOverrideEnvVars();

const BotIcon: React.FC<SvgIconProps> = (props) => {
  const themeOptions = configWPShortCode.theme === 'hello' ? styleHelloTheme : styleWASTheme;
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path fill={`${themeOptions.palette.primary.main}`}  strokeWidth={0} d="M21.93,11.28v-.4c0-1.29-.5-2.51-1.42-3.42-.81-.81-1.86-1.3-2.98-1.4v-1.28c.47-.26.79-.76.79-1.33,0-.84-.68-1.52-1.52-1.52s-1.52.68-1.52,1.52c0,.57.32,1.07.79,1.33v1.26H7.17c-1.29,0-2.51.5-3.42,1.42-.91.91-1.42,2.13-1.42,3.42v.36c-1.11.14-1.97,1.08-1.97,2.23s.86,2.09,1.97,2.23v6.11c0,.23.12.44.31.57.11.07.24.11.37.11.09,0,.18-.02.27-.06l3.81-1.66h10.01c1.29,0,2.51-.5,3.42-1.42.91-.91,1.42-2.13,1.42-3.42v-.17c1.15-.09,2.07-1.06,2.07-2.24s-.91-2.15-2.07-2.24ZM2.34,14.31c-.35-.12-.61-.45-.61-.84s.26-.73.61-.84v1.68ZM20.58,15.92c0,1.92-1.56,3.48-3.48,3.48H6.95c-.09,0-.19.02-.27.06l-2.99,1.3v-5.58c.01-.08,0-.18,0-.26v-2.99c0-.08.01-.11,0-.2v-.86c0-1.92,1.56-3.48,3.48-3.48h9.93c1.92,0,3.48,1.56,3.48,3.48v5.04ZM21.99,14.37v-1.71c.37.1.65.45.65.85s-.28.75-.65.85Z"/>
        <g>
          <circle fill={`${themeOptions.palette.primary.main}`} strokeWidth={0} cx="7.58" cy="13.35" r="1.35"/>
          <circle fill={`${themeOptions.palette.primary.main}`}  strokeWidth={0} cx="12.13" cy="13.46" r="1.35"/>
          <circle fill={`${themeOptions.palette.primary.main}`}  strokeWidth={0} cx="16.69" cy="13.35" r="1.35"/>
        </g>
      </svg>
    </SvgIcon>
  );
};

export default BotIcon;
