export const isDefined = <T>(value: T | undefined | null): value is T => {
    return value !== undefined && value !== null;
};

export const extractAndJoin = <T>(items: T[], attribute: keyof T): string => {
  return items.map(item => item[attribute]).join(',');
};

export const functionExists = (functionName: string, availableFunctions: any): boolean => {
  return functionName in availableFunctions;
};

export type ConvertTypeNameStringLiteralToType<T> = T extends "number"
  ? number
  : T extends "integer"
  ? number
  : T extends "string"
  ? string
  : T extends "boolean"
  ? boolean
  : T extends "object"
  ? object
  : T extends "array"
  ? unknown[]
  : T extends "null"
  ? null
  : never;

export const isNonEmptyString = (value: unknown): value is string => {
  return typeof value === "string" && value.length > 0;
};

export type RequiredAll<T> = {
  [K in keyof T]-?: T[K];
};

export type JsonAcceptable =
  | "string"
  | "integer"
  | "number"
  | "boolean"
  | "object"
  | "array"
  | "null";

export const toTimestamp = (date: Date) => {
  return Math.floor(date.getTime() / 1000);
};

export const fromTimestamp = (timestamp: number) => {
  return new Date(timestamp * 1000);
};

export const AssistantIdVersions = [
  'asst_tMx82D5bYwUL23BfsuckDyKX', // V0
  'asst_K8nyxlc9EhaGCmNQPLfanCZd', // V1
  'asst_yNdGfY6HQOopVihi6B12IZhS', // V2
  'asst_z1ul5PXUdnh7ZIgoFqGF5LL5', // TEST DEV
  'asst_HESwXGmQSHWDxlj03gY1UxUT', // Production Hello Italy
  'asst_bWUykCaiaFOBxsLxpOnEylph', // Production WAS Amsterdam
];

export const loadAndOverrideEnvVars = (configObject?: any, assistantElement: string = '.wrapper-was-assistant') => {
  let parsedConfigObject: any = {};
  if (!configObject) {
    const WPAssistantElement = document.querySelector(assistantElement);
    if (WPAssistantElement) {
      const dataAttsString = WPAssistantElement.getAttribute('data-atts');
      if (dataAttsString) {
        try {
          parsedConfigObject = JSON.parse(dataAttsString);
        } catch (error) {
          console.error('Errore nel parsing di data-atts:', error);
        }
      }
    }
  } else {
    parsedConfigObject = configObject;
  }

  if (Array.isArray(parsedConfigObject)) {
    return parsedConfigObject;
  }

  for (const key in process.env) {
    if (key.startsWith('REACT_APP_')) {
      const configKey = key.replace('REACT_APP_', '').toLowerCase();
      if (parsedConfigObject.hasOwnProperty(configKey)) {
        const envValue = process.env[key];
        const configValue = parsedConfigObject[configKey];
        if (typeof envValue === 'string') {
          process.env[key] = configValue.toString();
        } else if (typeof envValue === 'number') {
          process.env[key] = Number(configValue).toString();
        } else if (typeof envValue === 'boolean') {
          process.env[key] = configValue;
        }
      }
    }
  }
  return parsedConfigObject;
};

export const replaceTextWithLink = (text: string, openAIAnnotationFileId: string, openAIAnnotationFileText: string) => {
    const regex = new RegExp(`${openAIAnnotationFileText}`);
    const commonFileMappings: { [fileId: string]: string } = {
      "file-opOaGf1A49I4u71IAXTa92AD": "Codice-Etico-TIH-2022.official.docx.pdf",
      "file-H5wxFCOcvOzCdZYJI5YmPKDn": "HELLO-_-Work-Better-Policy-2022.official.docx.pdf",
      "file-WMSzNHmDCrxCLxFydwQuHWZd": "our-history-OR_cjsGn.pdf"
    };

    const helloItalyFileMappings: { [fileId: string]: string } = {
      "file-DHjNnrIgj2yii1WpS8okuMTy": "https://drive.google.com/file/d/1KitAx2BqJ0h1GCqHGC4hpIfdRCy4ThAc/view?usp=drive_link", // HELLO_Procedura per l_utilizzo di internet e e-mail aziendale.pdf
      "file-PoQUt6UByMjqcb3FDTGGZ3OJ": "https://drive.google.com/file/d/1l-R1IF567V0VqBN3cCnfaRs9BGQ_cWUV/view?usp=drive_link", // HELLO _ Work-Better-Policy-2022.official.docx.pdf
      "file-SAehvejERj7TjzGJnGI4X4Y4": "https://drive.google.com/file/d/1a9QmS6OmdbJsAGatNmUtGHKDgJl3SP1g/view?usp=drive_link", // Codice Etico TIH 2022.official.docx.pdf
      "file-d4weghDkIMZxskgfNLKaj9WI": "https://drive.google.com/file/d/1H-qRi8g7ysLw4BZttDBKJL5E-j1Yfloy/view?usp=drive_link", // HELLO_Istruzioni operative ai dipendenti.pdf
      "file-dtBitui78B3ANzHKJpDrLsI0": "https://drive.google.com/file/d/1Srevz8whcDEpxGWzqrwblb-xCkJy0Jdn/view?usp=drive_link", // DOG POLICY - HELLO.pdf
      "file-xAh4OmgQbAUapmHIz73SSN5i": "https://docs.google.com/presentation/d/1E6v-Ys3KLNd14IWFuOz1l0m2mIWxqDR4/edit?usp=drive_link&ouid=115309832910674700557&rtpof=true&sd=true", // HELLO - GREEN POLICY.pptx
      "file-2UAzhn6n6ISJWznfLIosvk8R": "https://docs.google.com/presentation/d/1sljdoolUqB1dxsmuaLAzdjlyRhW5NFU7/edit?usp=drive_link&ouid=115309832910674700557&rtpof=true&sd=true", // Hello Convenzioni Aziendali 2023.pptx
      "file-OaueUpPY89BtlU4k0pw05EOr": "https://docs.google.com/presentation/d/1fOMbc3EcygvlgiJYFP83YQrmypDZIxRB/edit?usp=drive_link&ouid=115309832910674700557&rtpof=true&sd=true", // We Are Social Passport - Global deck.pptx
      "file-lkWK4wFrAvFjvTP3gFCrY1DM": "https://docs.google.com/presentation/d/1-1VaXknTWulR0wWbYijpF04DEuCa0Bfp/edit?usp=drive_link&ouid=115309832910674700557&rtpof=true&sd=true", // TRAINING NOTA SPESE HELLO_2023.pptx
      "file-oh5eALzRZD18yFWwKkNuuDpi": "https://docs.google.com/presentation/d/1LBmm4ThE9yLKN6l8JKr77ExCOS0faBGR/edit?usp=drive_link&ouid=115309832910674700557&rtpof=true&sd=true", // HELLO _ Introducing U&I .pptx
      "file-6wi7p52MFR7J2cFGNOaqnPuj": "https://docs.google.com/presentation/d/1_EOT-DJ74d5jg36bWs7mKnkslrUt0Pyh/edit?usp=drive_link&ouid=115309832910674700557&rtpof=true&sd=true", // Hello,_leavers policy.pptx
      "file-F28vGVqKrixUleeENF3XlQcP": "https://docs.google.com/presentation/d/1OMT5v9Qkw3ms3gxjLAXEdQkN-O0bq1j7/edit?usp=drive_link&ouid=115309832910674700557&rtpof=true&sd=true", // Onboarding HELLO 2024.pptx
    };

    const wasAmsterdamFileMappings: { [fileId: string]: string } = {
      "file-BUcvXv5ZEPfPdy6mnU6TYRq4": "https://docs.google.com/document/d/1Wspe1qmnvW-5rA1t3clG9Ah1J_-ytb80/edit?usp=sharing&ouid=115309832910674700557&rtpof=true&sd=true" // 22.8.24_Employee Handbook - Amsterdam, Netherlands.docx
    };

    const fileMappings: { [assistantId: string]: { [fileId: string]: string } } = {
      "asst_tMx82D5bYwUL23BfsuckDyKX": helloItalyFileMappings, // V0
      "asst_K8nyxlc9EhaGCmNQPLfanCZd": helloItalyFileMappings, // V1
      "asst_yNdGfY6HQOopVihi6B12IZhS": helloItalyFileMappings, // V2
      "asst_z1ul5PXUdnh7ZIgoFqGF5LL5": helloItalyFileMappings, // TEST DEV
      "asst_HESwXGmQSHWDxlj03gY1UxUT": helloItalyFileMappings, // Production Hello Italy
      "asst_bWUykCaiaFOBxsLxpOnEylph": wasAmsterdamFileMappings, // Production WAS Amsterdam
    };

    const configWPShortCode = loadAndOverrideEnvVars();
    const currentAssistantId = configWPShortCode.openai_assistant_id || process.env.REACT_APP_OPENAI_ASSISTANT_ID;
    const mapping = fileMappings[currentAssistantId] || commonFileMappings;
    const nomeFile = mapping[openAIAnnotationFileId] || "FileNotFound";
    const link = ` <a href="${nomeFile}" target="_blank">${nomeFile}</a>`;
    // const link = `[${nomeFile}](${process.env.APP_URL}/data/${nomeFile}){:target="_blank"}`;
    return text.replace(regex, link);
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));