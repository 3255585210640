// src/components/Message.tsx
import React, { forwardRef, Fragment } from "react";
import { MessageDto } from "../models/MessageDto";
import { MuiMarkdown, getOverrides } from "mui-markdown";
import { ThemeProvider } from "@mui/material/styles";
// import markdownTheme from "../theme/markdownTheme";
// import styleTheme from "../theme/styleTheme";
import markdownHelloTheme from "../theme/hello/markdownTheme";
import styleHelloTheme from "../theme/hello/styleTheme";
import markdownWASTheme from "../theme/was/markdownTheme";
import styleWASTheme from "../theme/was/styleTheme";
import { Avatar, Box, Grid, Link, Paper, Typography } from "@mui/material";
import { styled } from "@mui/system";
import UserIcon from "./UserIcon";
import BotIcon from "./BotIcon";
import { Highlight, themes } from 'prism-react-renderer';
import { loadAndOverrideEnvVars } from '../utils/common-utils';
import WASIcon from "./WASIcon";

const configWPShortCode = loadAndOverrideEnvVars();
// import firstPath from "../ExampleMarkdown.md";
// let markdown_test;
// import * as fs from 'fs';
// readFile(firstPath, 'utf8', (err, data) => {
//   markdown_test = data;
// });
// fetch(firstPath).then(res => res.text()).then(text => { markdown_test = text; return text; });

interface MessageProps {
  message: MessageDto;
}

const ListItem = styled("li")(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

function MarkdownListItem(props: any) {
  return (
    <ListItem>
      <Typography component="span" {...props} />
    </ListItem>
  );
}

const Message: React.FC<MessageProps & { ref?: React.Ref<HTMLDivElement> }> = forwardRef<HTMLDivElement, MessageProps>(({ message }, ref) => {
  return (
    <Grid
      container
      wrap="nowrap"
      display="flex"
      spacing={2}
      direction={message.isUser ? "row-reverse" : "row"}
    >
      <Grid item>
        <Avatar
          sx={{
            bgcolor: message.isUser ? "primary.main" : "background.default",
            color: message.isUser ? "#ffffff" : "#000000",
          }}
        >
          {message.isUser ? (configWPShortCode.theme === "hello" ? <UserIcon fontSize="large" sx={{marginTop: '-0.5rem',marginRight: '-0.5rem'}} /> : <WASIcon fontSize="large" sx={{marginTop: '0rem',marginRight: '-0.45rem',zoom: 1.5}} />) : <BotIcon color="primary" />}
        </Avatar>
      </Grid>
      <Grid item xs>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item xs>
            <Box
              display="flex"
              justifyContent={message.isUser ? "flex-end" : "flex-start"}
            >
              <Paper
                variant="outlined"
                ref={ref}
                sx={{
                  // maxWidth: "75%",
                  // width: "100%",
                  p: 2,
                  bgcolor: message.isUser
                    ? "primary.main"
                    : "background.default",
                  color: message.isUser ? "#ffffff" : "#000000",
                  borderRadius: message.isUser
                    ? "16px 0px 16px 16px"
                    : "0px 16px 16px 16px",
                }}
              >
                  <Fragment>
                    <ThemeProvider
                      theme={message.isUser ? (configWPShortCode.theme === "hello" ? markdownHelloTheme : markdownWASTheme) : (configWPShortCode.theme === "hello" ? styleHelloTheme : styleWASTheme)}
                    >
                      <MuiMarkdown
                        overrides={{
                          ...getOverrides({ Highlight, themes, theme: themes.github, hideLineNumbers: true }), // This will keep the other default overrides.
                          h1: {
                            component: Typography,
                            props: {
                              gutterBottom: true,
                              variant: "h6",
                            },
                          },
                          h2: {
                            component: Typography,
                            props: { gutterBottom: true, variant: "h6" },
                          },
                          h3: {
                            component: Typography,
                            props: { gutterBottom: true, variant: "subtitle1" },
                          },
                          h4: {
                            component: Typography,
                            props: {
                              gutterBottom: true,
                              variant: "caption",
                              paragraph: true,
                            },
                          },
                          p: {
                            component: Typography,
                            props: { paragraph: true },
                          },
                          a: { component: Link },
                          li: {
                            component: MarkdownListItem,
                          },
                        }}
                      >
                        {message.content}
                      </MuiMarkdown>
                    </ThemeProvider>
                  </Fragment>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default Message;
