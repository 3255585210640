// src/theme/styleTheme.ts
import { createTheme, ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light', // Usando 'mode' invece di 'type'
    primary: {
      main: '#FF0037',
    },
    secondary: {
      main: '#E0E5E5',
    },
    background: {
      default: '#E0E5E5',
    },
    error: {
      main: '#FF3B5F',
    },
    warning: {
      main: '#013FFF',
    },
    success: {
      main: '#FF0037',
    },
    info: {
      main: '#FF0037',
    },
  },
  components: {
    MuiList: {
      defaultProps: {
        dense: true,
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFab: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: 'dense',
      },
      styleOverrides: {
        root: {
          // restore hidden placeholder
          formControl: {
            '& ::-webkit-input-placeholder': {
              opacity: '1.0!important',
            },
          }
        }
      }
    },
    MuiInputLabel: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
  },
  shape: {
    borderRadius: 16,
  },
};

const styleTheme = createTheme(themeOptions);

export default styleTheme;
