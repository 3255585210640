import { ConvertedFunctionParamProps } from "../../types/ConvertedFunctionParamProps";
import { GetUserProps } from "../../types/GetUserProps";

export const get_current_user = async (
    args: ConvertedFunctionParamProps<GetUserProps>
): Promise<string> => {
    const { user_id } = args;
    try {
        const options = {
            method: 'GET',
            headers: {
                Authorization: `Basic ${process.env.REACT_APP_LEARNING_API_TOKEN}`
            }
        };
        const response = await fetch(`${process.env.REACT_APP_LEARNING_URL}/wp-json/wp/v2/users/${user_id}`, options);
        if (!response.ok) {
            throw new Error('Unable to Fetch Data, Please check URL or Network connectivity!');
        }
        const data = await response.json();
        return JSON.stringify({
            id: data.id,
            name: data.name,
            // link: data.link,
            slug: data.slug
        });
    } catch (error) {
        console.error(error);
        return JSON.stringify({ message: 'Failed to fetch current user', error: error });
    }
};