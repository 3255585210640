import { DescribedFunctionName } from "../function-descriptions";
import { get_current_user } from "./get_current_user";
import { get_today } from "./get_today";
import { get_list_user_courses } from "./get_list_user_courses";
import { get_list_courses } from "./get_list_courses";

export default {
  get_today,
  get_list_user_courses,
  get_list_courses,
  get_current_user
} satisfies Record<DescribedFunctionName, (...args: any[]) => Promise<string>>;