// src/components/UserIcon.tsx
import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";
// import themeOptions from "../theme/styleTheme";
import { loadAndOverrideEnvVars } from '../utils/common-utils';
import styleHelloTheme from "../theme/hello/styleTheme";
import styleWASTheme from "../theme/was/styleTheme";

const configWPShortCode = loadAndOverrideEnvVars();

const WASIcon: React.FC<SvgIconProps> = (props) => {
  const themeOptions = configWPShortCode.theme === 'hello' ? styleHelloTheme : styleWASTheme;
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={`${themeOptions.palette.primary.main}`}
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle strokeWidth={0} fill={`${themeOptions.palette.primary.main}`} cx="12" cy="12" r="12"/>
        <polygon strokeWidth={0} fill="#ffffff" points="6.02 10.02 5.58 8.61 5.55 8.61 5.11 10.02 4.58 10.02 3.79 7.9 4.43 7.9 4.85 9.2 4.9 9.2 5.26 7.9 5.86 7.9 6.23 9.2 6.28 9.2 6.7 7.9 7.34 7.9 6.55 10.02 6.02 10.02"/>
        <path strokeWidth={0} fill="#ffffff" d="M8.55,10.08c-.7,0-1.15-.44-1.15-1.12,0-.31.1-.59.29-.79.2-.21.48-.33.83-.33s.61.1.81.3c.19.19.3.45.3.74,0,.08,0,.12-.02.23h-1.55c0,.44.26.52.49.52.21,0,.35-.08.42-.25h.63c-.09.43-.49.7-1.04.7h0ZM8.52,8.28c-.28,0-.47.17-.47.46h.94c0-.28-.19-.47-.47-.47h0Z"/>
        <path strokeWidth={0} fill="#ffffff" d="M9.5,12.95c-.7,0-1.15-.44-1.15-1.12,0-.31.1-.59.29-.79.2-.21.48-.33.83-.33s.61.1.81.3c.19.19.3.45.3.74,0,.08,0,.12-.02.23h-1.55c0,.44.26.52.49.52.21,0,.35-.08.42-.25h.63c-.09.43-.49.7-1.04.7h0ZM9.47,11.16c-.28,0-.47.17-.47.46h.94c0-.28-.19-.47-.47-.47h0Z"/>
        <path strokeWidth={0} fill="#ffffff" d="M4.94,12.94c-.27,0-.53-.11-.71-.32-.19-.21-.29-.49-.29-.8s.1-.59.29-.8.44-.32.71-.32c.29,0,.55.14.67.35h.04v-.3h.65v2.12h-.65v-.29h-.04c-.12.21-.38.35-.67.35ZM5.12,11.26c-.3,0-.53.25-.53.57s.23.57.53.57.53-.25.53-.57-.23-.57-.53-.57Z"/>
        <path strokeWidth={0} fill="#ffffff" d="M13.19,15.81c-.27,0-.53-.11-.71-.32-.19-.21-.29-.49-.29-.8s.1-.59.29-.8c.19-.2.44-.32.71-.32.29,0,.55.14.67.35h.04v-.3h.65v2.12h-.65v-.29h-.04c-.12.21-.38.35-.67.35ZM13.36,14.13c-.3,0-.53.25-.53.57s.23.57.53.57.53-.25.53-.57-.23-.57-.53-.57Z"/>
        <path strokeWidth={0} fill="#ffffff" d="M6.7,12.89v-2.12h.65v.34h.04c.09-.18.33-.37.65-.37.07,0,.13,0,.18.03l-.03.58c-.09-.02-.11-.03-.18-.03-.25,0-.45.09-.56.25-.08.12-.09.28-.09.49v.82s-.65,0-.65,0Z"/>
        <path strokeWidth={0} fill="#ffffff" d="M3.93,15.08c0,.47.37.74.99.74.28,0,.94-.07.94-.67,0-.42-.32-.51-.68-.62-.06-.02-.12-.04-.17-.05-.25-.08-.4-.13-.4-.27,0-.13.11-.21.28-.21.18,0,.29.1.3.28h.64c0-.44-.35-.69-.94-.69s-.91.23-.91.64c0,.38.26.48.65.61.06.02.11.03.15.05.3.09.44.14.44.29,0,.14-.11.22-.3.22-.23,0-.36-.11-.36-.32h-.63Z"/>
        <path strokeWidth={0} fill="#ffffff" d="M7.28,15.82c-.35,0-.65-.12-.87-.33-.2-.2-.31-.48-.31-.79s.11-.58.31-.79c.22-.22.52-.33.87-.33s.65.12.86.33c.2.2.31.48.31.79s-.11.58-.31.79c-.21.22-.51.33-.86.33ZM7.28,14.13c-.3,0-.53.25-.53.57s.22.57.53.57.54-.24.54-.57-.23-.57-.54-.57Z"/>
        <path strokeWidth={0} fill="#ffffff" d="M9.86,15.82c-.36,0-.66-.12-.87-.34-.19-.2-.29-.48-.29-.78,0-.56.36-1.12,1.15-1.12.73,0,1.04.46,1.08.87h-.65c-.03-.2-.19-.32-.43-.32-.32,0-.52.21-.52.58s.2.58.53.58c.22,0,.35-.09.41-.29h.65c-.08.51-.49.83-1.09.83h0Z"/>
        <path strokeWidth={0} fill="#ffffff" d="M11.23,15.76v-2.12h.65v2.12h-.65ZM11.55,13.32c-.23,0-.42-.19-.42-.42s.18-.42.42-.42.42.18.42.42-.19.42-.42.42Z"/>
        <rect strokeWidth={0} fill="#ffffff" x="14.89" y="12.66" width=".65" height="3.1"/>
        <path strokeWidth={0} fill="none" d="M10.92,18.04H1.75V5.62h20.39v12.42s0,.53,0,.5"/>
      </svg>
    </SvgIcon>
  );
};

export default WASIcon;