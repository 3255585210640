// src/components/UserIcon.tsx
import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const SendIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="0"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path fill='currentColor' d="M22.2,12.53s0,0,0,.01l-9.71,4.24-9.71,4.24s-.02,0-.02-.01,0,0,0,0l2.91-7.66,9.24-.69c.15-.01.15-.23,0-.24l-9.24-.69-2.91-7.66s0,0,0,0,0-.02.02-.01l9.71,4.24,9.71,4.24s0,0,0,.01h0ZM23.51,12.53c0-.53-.31-1-.79-1.21l-9.71-4.24L3.29,2.84c-.41-.18-.88-.14-1.25.11-.37.25-.6.66-.6,1.11,0,.16.03.32.09.47l2.91,7.66.13.34-.13.34-2.91,7.66c-.06.15-.09.31-.09.47,0,.45.22.86.6,1.11.37.24.84.28,1.25.11l9.71-4.24,9.71-4.24c.48-.21.79-.69.79-1.21h0Z"/>
      </svg>
    </SvgIcon>
  );
};

export default SendIcon;
