// src/components/Chat.tsx
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  TextField,
  Box,
  Button,
  CircularProgress,
  Divider,
} from "@mui/material";
import Message from "./Message";
import { MessageDto } from "../models/MessageDto";
// import SendIcon from "@mui/icons-material/Send";
// import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ChatHeader from "./ChatHeader";
import Questions from "./Questions";
import { Header } from "./Header";
import {retrieveAssistant, getMessageList, createRun, waitOnRun, retrieveRun, createThread, createThreadMessage, createNewMessage, createRunStream, streamEvents} from "../api/openai";
import {replaceTextWithLink, toTimestamp} from "../utils/common-utils";
// import themeOptions from "../theme/styleTheme";
import SendIcon from "./SendIcon";
import styleHelloTheme from "../theme/hello/styleTheme";
import styleWASTheme from "../theme/was/styleTheme";
import { loadAndOverrideEnvVars } from '../utils/common-utils';

// import { BorderColor, BorderStyle } from "@mui/icons-material";
// import { ActionCallbackType } from "../types/ActionCallbackType";
// import { getToday } from "../api/functions";

const Chat: React.FC = () => {
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [messages, setMessages] = useState<Array<MessageDto>>(new Array<MessageDto>());
  const [input, setInput] = useState<string>("");
  const [assistant, setAssistant] = useState<any>(null);
  const [thread, setThread] = useState<any>(null);
  const [openai, setOpenai] = useState<any>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const streamingMessageEndRef = useRef<HTMLDivElement | null>(null);
  const [showChipList, setShowChipList] = useState<boolean>(true);
  const [selectedChip, setSelectedChip] = useState<string | null>(null);
  const [streamingMessage, setStreamingMessage] = useState<MessageDto>({
    isUser: false,
    content: "_Thinking..._",
    role: 'assistant',
    created_at: null,
    completed_at: null,
    incomplete_at: null,
    incomplete_details: null,
  });
  const [runStepStatus, setRunStepStatus] = useState<any>(null);
  const [messageStatus, setMessageStatus] = useState<any>(null);
  const [runId, setRunId] = useState<any>(null);
  const [runStatus, setRunStatus] = useState<any>(null);
  const configWPShortCode = loadAndOverrideEnvVars();
  const themeOptions = configWPShortCode.theme === 'hello' ? styleHelloTheme : styleWASTheme;
  const questionsOptions = configWPShortCode.questions ? configWPShortCode.questions : [];

  useEffect(() => {
    initChatBot();
  }, []);

  // useEffect(() => {
  //   setMessages([
  //     {
  //       content: "Hi, I'm your personal assistant. How can I help you?",
  //       isUser: false,
  //     },
  //   ]);
  // }, [assistant]);

  const initChatBot = async () => {
    const assistant = await retrieveAssistant();
    const thread = await createThread();
    setOpenai(openai);
    setAssistant(assistant);
    setThread(thread);
  };

  const handleSendMessage = async () => {
    if (input.trim() === '') return;
    setIsWaiting(true);
    setShowChipList(false);
    messages.push(createNewMessage(input.trim(), true));
    setMessages([...messages]);
    setInput("");
    setStreamingMessage({
      isUser: false,
      content: '_Thinking..._',
      role: 'assistant',
      created_at: toTimestamp(new Date()),
      completed_at: toTimestamp(new Date()),
      incomplete_at: null,
      incomplete_details: null
    });

    // Send a message to the thread
    await createThreadMessage(thread.id, input.trim(), "user");

    // Run the assistant
    const stream = process.env.REACT_APP_OPENAI_STREAM;
    if (stream === "true") {
      console.log('ThreadID: ', thread.id);
      const stream = await createRunStream(thread.id, assistant.id, '');
      await streamEvents(
        stream,
        (onRunStatusUpdate) => {
          setRunStatus(onRunStatusUpdate);
          setStreamingMessage({
            isUser: false,
            content: '_Thinking..._',
            role: 'assistant',
            created_at: toTimestamp(new Date()),
            completed_at: toTimestamp(new Date()),
            incomplete_at: null,
            incomplete_details: null
          });
          console.log('onRunStatusUpdate', onRunStatusUpdate);
        },
        (onThreadMessageStatusUpdate) => {
          setMessageStatus(onThreadMessageStatusUpdate);
          console.log('onThreadMessageStatusUpdate', onThreadMessageStatusUpdate);
        },
        (onRunIdUpdate) => {
          setRunId(onRunIdUpdate);
          console.log('onRunIdUpdate', onRunIdUpdate);
        },
        (onMessageUpdate) => {
          setStreamingMessage(onMessageUpdate);
          console.log('updatedMessage', onMessageUpdate);
        },
        (onStreamEnd) => {
          setIsWaiting(false);
          setMessages([...messages, onStreamEnd]);
          console.log('finalMessage', onStreamEnd);
        },
        (onError) => {
          console.error("Stream error:", onError);
          setIsWaiting(false);
        },
        (onRunStepStatusUpdate) => {
          setRunStepStatus(onRunStepStatusUpdate);
          console.log('onRunStepStatusUpdate', onRunStepStatusUpdate);
        }
      );
      setIsWaiting(false);
      console.log('endAwaitStream', true);
    } else {
      setIsWaiting(true);
      setStreamingMessage(createNewMessage('_Thinking..._', false, 'assistant', null));

      const run = await createRun(thread.id, assistant.id);

      // Create a response
      let response = await retrieveRun(thread.id, run.id);

      // Wait for the response to be ready
      await waitOnRun(thread.id, response);
      setIsWaiting(false);

      // Get the messages for the thread
      const messageList = await getMessageList(thread.id);

      // Find the last message for the current run
      const lastMessage = messageList.data
        .filter((message: any) => message.run_id === run.id && message.role === "assistant")
        .pop();

      // Print the last message coming from the assistant
      if (lastMessage) {
        console.log(lastMessage.content[0]["text"].value);
        let lastMessageAnswer = lastMessage.content[0]["text"].value;
        // replace source text with mapping link
        if (lastMessage.content[0]["text"].annotations && lastMessage.content[0]["text"].annotations.length > 0) {
          for (let i = 0; i < lastMessage.content[0]["text"].annotations.length; i++) {
            let openAIAnnotationFileText = lastMessage.content[0]["text"].annotations[i].text;
            let openAIAnnotationFileId = lastMessage.content[0]["text"].annotations[i].file_citation.file_id;
            lastMessageAnswer = replaceTextWithLink(lastMessageAnswer, openAIAnnotationFileId, openAIAnnotationFileText)
          }
        }
        setMessages([...messages, createNewMessage(lastMessageAnswer, false, 'assistant')]);
      }
    }
  };

  useEffect(() => {
    if (selectedChip !== null) {
      handleSendMessage();
    }
  }, [selectedChip]);

  const handleChipSelect = (chip: string) => {
    setShowChipList(false);
    setInput(chip);
    setSelectedChip(chip);
  };

  // detect enter key and send message
  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    if (streamingMessageEndRef.current) {
      streamingMessageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [streamingMessage]);

  return (
    <Fragment>
      <ChatHeader text="HeyMate" />
      <Box flex="1" overflow="auto" p={3}>
        <Box display="flex" flexDirection="column" gap={2}>
          {showChipList && <Header />}
          <Box display="flex" justifyContent="center">
            {showChipList && (
              <Questions chips={questionsOptions} onChipSelect={handleChipSelect} />
            )}
          </Box>
          {messages.map((message, index) => (
            <Message key={index} message={message} ref={index === messages.length - 1 ? messagesEndRef : null} />
          ))}
          {isWaiting &&
            <Message message={streamingMessage} ref={streamingMessageEndRef} />
          }
        </Box>
      </Box>
      <Divider />
      <Box
        p={2}
        display="flex"
        alignItems="center"
        bgcolor="primary.main"
        sx={{ borderRadius: "0 0 16px 16px" }}
      >
        <TextField
            disabled={isWaiting}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyPress}
            variant="outlined"
            placeholder="Type your message..."
            fullWidth
            multiline
            maxRows={4}
            inputProps={{
              sx: {
                borderStyle: 'unset !important',
                borderColor: `${themeOptions.palette.primary.main} !important`,
                color: `${themeOptions.palette.primary.main} !important`,
                '::placeholder': {
                  opacity: 1
                }
              }
            }}
            sx={{
              mr: 2,
              '& label.Mui-focused': {
                color: '#fff !important',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#fff !important',
              },
              '& .MuiOutlinedInput-root': {
                color: `${themeOptions.palette.primary.main} !important`,
                backgroundColor: "#fff !important",
                borderRadius: "30px",
                fontWeight: 'bold',
                '& textarea': {
                  borderStyle: 'unset !important'
                },
                '& fieldset': {
                  borderColor: '#fff !important',
                },
                '&:hover fieldset': {
                  borderColor: '#fff !important',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#fff !important',
                },
              },
            }}
        />
        <Button
          aria-label="send"
          variant="outlined"
          color="primary"
          onClick={handleSendMessage}
          disabled={isWaiting}
          sx={{
            backgroundColor: "#fff",
            borderRadius: "30px !important",
            '&:hover': {
              color: '#fff',
              borderColor: '#fff',
            }
          }}
        >
          {isWaiting ? (
            <CircularProgress size={35} color="primary" />
          ) : (
            // <SendOutlinedIcon fontSize="large" />
            <SendIcon fontSize="large" />
          )}
        </Button>
      </Box>
    </Fragment>
  );
};

export default Chat;
